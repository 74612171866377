<template>
  <!-- 改了 -->
  <div class="home" :class="show ? 'ish5' : ''">
    <div class="htop">
      <mtop />
    </div>
    <!-- <div class="banner">
			<img src="../assets/sdImages/about/Maskgroup.png" alt="szzc" class="simg" />
		</div> -->
    <div class="banner">
      <div class="banimg">
        <div class="title">光伏物联网监测平台</div>
        <div class="tip">
          利用最新的物联网技术，通过连接太阳能光伏板、MTTP控制器、网关和传感器设<br />备，为用户提供一个集中的、可视化的监控界面。用户可以实时查看系统状态，进<br />行数据分析，并根据需要进行远程控制。可以实时采集监控接入传感器设备的数<br />据，并将这些数据传输到云平台，以便用户进行远程监控和分析。
        </div>
        <div class="btn-box">
          <div class="btn" @click="dialogVisible = true, clickMenu('免费试用')">免费试用</div>
          <!-- <div class="btn2">预约演示</div> -->
        </div>
      </div>
    </div>

    <!-- 产品及服务项目 -->
    <div class="content">
      <div class="box">
        <div class="btop">
          <div class="title">产品亮点</div>
          <!-- <div class="tips">以用户为中心，提供智能化产品赋能企业数智化转型</div> -->
        </div>
        <div class="ld-box">
          <div class="context">
            <div class="left-img">
              <img src="@/assets/sdImages/case2/keshihua1@2x.png" />
            </div>
            <div class="right-text">
              <div class="cont">远程4G监测，可视化管理WEB</div>
              <div class="cont">云系统/APP/小程序</div>
            </div>
          </div>
          <div class="context">
            <div class="left-img">
              <img src="@/assets/sdImages/case2/shipei1@2x.png" />
            </div>
            <div class="right-text">
              <div class="cont">多种能源输入/输出方式满足</div>
              <div class="cont">各种设备用电需求</div>
            </div>
          </div>
          <div class="context">
            <div class="left-img">
              <img src="@/assets/sdImages/case2/toolbai1@2x.png" />
            </div>
            <div class="right-text">
              <div class="cont">支持485协议传感设备，可实时</div>
              <div class="cont">获取接入设备采集数据</div>
            </div>
          </div>
          <div class="context">
            <div class="left-img">
              <img src="@/assets/sdImages/case2/yingyong1@2x.png" />
            </div>
            <div class="right-text">
              <div class="cont">应用场景广泛，涵盖农业、水利、</div>
              <div class="cont">环境等领域物联设备智控</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content hbg">
      <div class="box">
        <div class="btop">
          <div class="title">产品功能</div>
          <!-- <div class="tips">
            用我们的专业和诚信赢得您的信赖，从PC到移动互联网均有您想要的服务！
          </div> -->
        </div>
        <div class="lint">
          <div class="card">
            <img
              src="@/assets/sdImages/case2/gongdiansuo-21@2x.png"
              class="ibg"
            />
            <div class="header">供电状态实时监测</div>
            <div class="cbox">实时查看太阳能光伏组供电情况</div>
          </div>
          <div class="card">
            <img src="@/assets/sdImages/case2/Group12458@2x.png" class="ibg" />
            <div class="header">网络状态实时监测</div>
            <div class="cbox">设备网络状况实时查看监测</div>
          </div>
          <div class="card">
            <img
              src="@/assets/sdImages/case2/baojingguanli1@2x.png"
              class="ibg"
            />
            <div class="header">故障信息自动报警</div>
            <div class="cbox">可实现设备故障自动报警功能</div>
          </div>
          <div class="card">
            <img src="@/assets/sdImages/case2/a-1@2x.png" class="ibg" />
            <div class="header">供电输出远程开关</div>
            <div class="cbox">支持远程控制接入设备的启动与关闭</div>
          </div>
          <div class="card">
            <img
              src="@/assets/sdImages/case2/shebeizhineng1@2x.png"
              class="ibg"
            />
            <div class="header">设备参数远程设置</div>
            <div class="cbox">支持远程对设备进行参数调整和管理</div>
          </div>
          <div class="card">
            <img
              src="@/assets/sdImages/case2/qichebaoyangweixiushenqing1@2x.png"
              class="ibg"
            />
            <div class="header">维修处理自动导航</div>
            <div class="cbox">如发生设备故障支持导航定位设备位置</div>
          </div>
          <div class="card">
            <img src="@/assets/sdImages/case2/a-qi1@2x.png" class="ibg" />
            <div class="header">数据采集自动上传</div>
            <div class="cbox">根据数据传输协议自动上传数据至云端平台</div>
          </div>
          <div class="card">
            <img src="@/assets/sdImages/case2/Group12456@2x.png" class="ibg" />
            <div class="header">数据分析生成报告</div>
            <div class="cbox">实时采集数据并形成多维报表</div>
          </div>
        </div>
      </div>
    </div>
    <!--  为什么选择我们 -->
    <div class="content abg">
      <div class="box">
        <div class="btop">
          <div class="title">产品价值</div>
          <!-- <div class="tips">
            用我们的专业和诚信赢得您的信赖，从PC到移动互联网均有您想要的服务！
          </div> -->
        </div>
        <div class="ul2">
          <div class="left">
            <div
              class="item"
              :class="type == 1 ? 'act' : ''"
              @click="toChange(1)"
            >
              <div class="title">远程运维，无人智控</div>
              <div class="text">
                通过光伏物联网监测平台，可以实时监控光伏电站的运行状态，及时发现并处理设备故障，从而减少运维管控的人为操作成本与事故，实现无人值守的智慧光伏电站新形势。
              </div>
            </div>
            <div
              class="item"
              :class="type == 2 ? 'act' : ''"
              @click="toChange(2)"
            >
              <div class="title">一键上云，一站式管理</div>
              <div class="text">
                搭载物联网平台实现远程设备配置和场景联动配置，支持WEB、小程序登入平台，设置参数，实时数据采集，历史数据图表分析比对。
              </div>
            </div>
          </div>
          <div class="right">
            <img
              src="@/assets/sdImages/case2/Group12479@2x.png"
              class="fadeInRight"
              v-if="type == 1"
            />
            <img
              src="@/assets/sdImages/case2/Group12480@2x.png"
              class="fadeInRight"
              v-if="type == 2"
            />
          </div>
        </div>
        <div class="ul3">
          <div class="left">
            <img
              src="@/assets/sdImages/case2/Group12481.png"
              class="fadeInLeft"
              v-if="type2 == 1"
            />
            <img
              src="@/assets/sdImages/case2/Group12482.png"
              class="fadeInLeft"
              v-if="type2 == 2"
            />
            <img
              src="@/assets/sdImages/case2/Group12483.png"
              class="fadeInLeft"
              v-if="type2 == 3"
            />
          </div>
          <div class="right">
            <div
              class="item"
              :class="type2 == 1 ? 'act' : ''"
              @click="toChange2(1)"
            >
              <div class="title">全景化监控，设备智能管理</div>
              <div class="text">
                监测平台能够提供实时监控、故障诊断、评估分析、运维服务、报表统计等功能，满足企业对设备运行的全景化监控和智能管理需求。
              </div>
            </div>
            <div
              class="item"
              :class="type2 == 2 ? 'act' : ''"
              @click="toChange2(2)"
            >
              <div class="title">灵活部署，扩展组件</div>
              <div class="text">
                可以自由配置Modbus协议的RS485接口的设备，如市面上各种环境、土壤、水质等传感器
              </div>
            </div>
            <div
              class="item"
              :class="type2 == 3 ? 'act' : ''"
              @click="toChange2(3)"
            >
              <div class="title">赋能产业智能化升级</div>
              <div class="text">
                光伏物联网监测平台通过集成先进的信息和通信技术，如物联网、大数据、云计算、数字孪生等，提升光伏电站的运营效率和可靠性，降低运维成本。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 客户案例 -->
    <div class="content hbg">
      <div class="box">
        <div class="btop">
          <div class="title">应用场景</div>
          <!-- <div class="tips">用可信、高效的技术方案助力产业协作数字化升级</div> -->
        </div>
        <div class="case"></div>
        <div class="opts">
          <div
            class="opts-child"
            v-for="(item, index) in someList"
            :key="index"
            @click="(someIndex = index), someChange(index)"
            :class="someIndex == index ? 'active' : ''"
          >
            <div class="img">
              <img
                :src="someIndex == index ? item.acimage : item.image"
                alt=""
              />
            </div>
            <div class="title">{{ item.title }}</div>
          </div>
        </div>
        <div class="opts-text">
          <div class="left-flag">
            <img src="../assets/sdImages/party/Group1608.png" alt="" />
          </div>
          <div class="center-content">
            {{ someContent }}
          </div>
          <div class="right-flag">
            <img src="../assets/sdImages/party/Group1609.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="content abg">
      <div class="box">
        <div class="btop">
          <div class="title">平台主要组成</div>
          <!-- <div class="tips">
            用我们的专业和诚信赢得您的信赖，从PC到移动互联网均有您想要的服务！
          </div> -->
        </div>
        <div class="lint2">
          <div class="card">
            <img src="@/assets/sdImages/case2/taiyangneng.png" class="ibg" />
            <div class="header">太阳能光伏板组</div>
            <div class="cbox">
              高效率太阳能电池片，确保最大能量 转换抗风压和耐腐蚀设计，适应各种环境条件模块化安装，便于扩展和维护
            </div>
          </div>
          <div class="card">
            <img src="@/assets/sdImages/case2/image206@2x.png" class="ibg" />
            <div class="header">MTTP控制器</div>
            <div class="cbox">
              智能控制，优化能源分配和存储实时监控系统状态，及时发现并解决问题支持多种通信协议，方便与其他设备连接
            </div>
          </div>
          <div class="card">
            <img src="@/assets/sdImages/case2/image207@2x.png" class="ibg" />
            <div class="header">485协议网关</div>
            <div class="cbox">
              支持RS-485通信协议，实现设备间的数据交换强大的数据处理能力，确保数据的准确性和实时性。易于集成到现有的物联网平台。
            </div>
          </div>
          <div class="card">
            <img src="@/assets/sdImages/case2/image208@2x.png" class="ibg" />
            <div class="header">传感器设备</div>
            <div class="cbox">
              温度、湿度、光照强度等多种传感器，全面监测环境参数。高精度数据采集，为系统优化提供支持。低功耗设计，延长使用寿命。
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 合作伙伴 -->
    <div class="content hzbg relative-filter">
      <div class="left-zg"></div>
      <div class="right-zg"></div>
      <div class="box">
        <div class="btop">
          <div class="title">合作伙伴</div>
<!--          <div class="tips">-->
<!--            生态合作伙伴/业务合作伙伴展示,旨在突出合作的价值和卓越的业务能力。-->
<!--          </div>-->
        </div>
        <div
          class="partner swiper-container swiper-container1"
          id="imgSwiper"
          ref="myimgSwiper"
        >
          <!-- <div class="Gtitle">合作伙伴</div> -->
          <div class="swiper-wrapper">
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/1.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/2.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/3.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/4.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/5.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/6.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/7.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/8.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/9.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/10.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/11.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/12.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/13.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/14.png" alt="" />
            </div>
          </div>
        </div>

        <div
          class="partner swiper-container swiper-container1"
          id="imgSwiper2"
          ref="myimgSwiper"
        >
          <!-- <div class="Gtitle">合作伙伴</div> -->
          <div class="swiper-wrapper">
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/15.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/16.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/17.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/18.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/19.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/20.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/21.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/22.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/23.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/24.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/25.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/26.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/27.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/28.png" alt="" />
            </div>
          </div>
        </div>

        <div
          class="partner swiper-container swiper-container1"
          id="imgSwiper3"
          ref="myimgSwiper"
        >
          <!-- <div class="Gtitle">合作伙伴</div> -->
          <div class="swiper-wrapper">
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/29.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/30.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/31.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/32.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/33.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/34.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/35.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/36.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/37.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/38.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/39.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/40.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/41.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/42.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="last-content">
      <div class="box">
        <div class="last-text">现在，非常期待与您的又一次邂逅</div>
        <div class="last-btn">
          <div class="contant">
            <span>立即联系</span>
          </div>
          <div class="play">
            <span>预约演示</span>
          </div>
        </div>
      </div>
    </div> -->
    <fotterBanner></fotterBanner>
    <mbottom />
    <demonstrate
      :dialogVisible="dialogVisible"
      @closeDialog="closeDialog"
    ></demonstrate>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
import fotterBanner from "../components/fotterBanner.vue";
import { staClickMenu } from "../utils/index";
import demonstrate from "../components/demonstrate.vue";
export default {
  name: "HomeView",
  components: {
    mtop,
    mbottom,
    demonstrate,
    fotterBanner
  },
  data() {
    return {
      show: false,
      current: 0,
      current2: 0,
      current3: 1,
      caseNav: [],
      caseList: [],
      newsList: [],
      fqaList: [],
      page: 1,
      jqNewsBtns: [
        { id: "1", name: "新闻中心" },
        { id: "2", name: "行业动态" },
        { id: "3", name: "技术学堂" },
      ],
      swiperList: [],
      topList: [],
      dialogVisible: false,
      type: 1,
      type2: 1,
      someList: [
        {
          image: require("@/assets/sdImages/case2/Frame12478_slices/gongpeidianxitong2.png"),
          acimage: require("@/assets/sdImages/case2/Frame12478_slices/gongpeidianxitong3.png"),
          title: "大型光伏电站",
        },
        {
          image: require("@/assets/sdImages/case2/Frame12478_slices/yunweifenxipingta1@2x.png"),
          acimage: require("@/assets/sdImages/case2/Frame12478_slices/yunweifenxipingai1@2x.png"),
          title: "智能运维",
        },
        {
          image: require("@/assets/sdImages/case2/Frame12478_slices/nengyua1@2x.png"),
          acimage: require("@/assets/sdImages/case2/Frame12478_slices/nengyuan1@2x.png"),
          title: "能源管理",
        },
        {
          image: require("@/assets/sdImages/case2/Frame12478_slices/city@2x.png"),
          acimage: require("@/assets/sdImages/case2/Frame12478_slices/city1@2x.png"),
          title: "智慧城市建设",
        },
        {
          image: require("@/assets/sdImages/case2/Frame12478_slices/guangfu-1@2x.png"),
          acimage: require("@/assets/sdImages/case2/Frame12478_slices/guangfu-41@2x.png"),
          title: "农业光伏",
        },
        {
          image: require("@/assets/sdImages/case2/Frame12478_slices/equipment-monitoring1@2x.png"),
          acimage: require("@/assets/sdImages/case2/Frame12478_slices/equipmentmonitoring@2x.png"),
          title: "环境监测",
        },
        {
          image: require("@/assets/sdImages/case2/Frame12478_slices/Group1487@2x.png"),
          acimage: require("@/assets/sdImages/case2/Frame12478_slices/Group12487@2x.png"),
          title: "水利监测",
        },
      ],
      someContent:
        "对于大规模的光伏电站，物联网监测平台可以实时监控电站的发电效率、设备状态和环境因素，实现高效运维。",
      someIndex: 0,
    };
  },
  created() {
    // 获取轮播图
    // this.getSwiperList()
    // 获取新闻列表
    // this.getList();
    // this.getList2();
  },
  mounted() {
    // this.getCaseNav();
    // this.getNews();
    // this.getarticleList();
    this.initImgSwiper();
    this.initImgSwiper2();
    this.initImgSwiper3();
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
  },
  watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
    },
    deep: true,
    immediate: true,
  },
  computed: {
    getHot() {
      if (this.newsList.length > 0) {
        return this.newsList[0];
      } else {
        return [];
      }
    },
    getHotItem() {
      if (this.newsList.length > 2) {
        return this.newsList[1];
      } else {
        return [];
      }
    },
    getNewsList() {
      if (this.newsList.length > 3) {
        return this.newsList.filter((item, index) => {
          if (index > 1) {
            return item;
          }
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    someChange(index) {
      if (index == 0) {
        this.someContent =
          "对于大规模的光伏电站，物联网监测平台可以实时监控电站的发电效率、设备状态和环境因素，实现高效运维。";
      } else if (index == 1) {
        this.someContent =
          "通过集成物联网传感器和智能设备，监测平台能够实现对光伏设备的智能巡检、故障预警和远程维护";
      } else if (index == 2) {
        this.someContent =
          "企业可以利用监测平台进行能源消耗的监控和管理，实现能源使用的最优化";
      } else if (index == 3) {
        this.someContent =
          "在智慧城市项目中，光伏物联网监测平台可以作为智能能源管理系统的一部分，支持城市的可持续发展。如路灯监管等。";
      } else if (index == 4) {
        this.someContent =
          "在农业领域，光伏发电可以与农业设施结合，监测平台有助于监控和管理这种结合系统的能源产出和使用。";
      } else if (index == 5) {
        this.someContent =
          "光伏电站通常位于开阔地区，监测平台可以集成环境监测功能，如气象数据收集，为科学研究和天气预报提供支持。";
      } else {
        this.someContent =
          "利用光伏扬水系统，为边远无电或缺电地区的农林灌溉、荒漠治理、草原畜牧、生活用水、苦咸水淡化等提供动力电源和系统解决方案。";
      }
    },
    toChange(e) {
      this.type = e;
    },
    toChange2(e) {
      this.type2 = e;
    },
    initMore() {
      // eslint-disable-next-line
      let vueComponent = this; //获取vue组件实例
      //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
      new Swiper("#more", {
        loop: false, // 循环模式选项
        slidesPerView: "auto",
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
      });
    },
    // 统计菜单点击
    clickMenu(menuName, url) {
      if (url) {
        this.$router.replace({
          path: url,
        });
      } else {
        if (menuName != '免费试用') {
          this.$toast("敬请期待");
        }
      }
      staClickMenu(menuName)
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    // 获取列表数据
    async getList() {
      // this.topList = []
      // this.newsList = []
      let res = await this.$http.post(this.$api.listArticle, {
        current: 1,
        size: 3,
        typeId: `1,${Number(this.current2) + 2}`,
        status: "1",
        top: "0",
      });
      this.newsList = res.data.data.records;
      localStorage.removeItem("newsListdd");
      localStorage.setItem("newsListdd", JSON.stringify(this.newsList));
    },
    // 获取置顶数据
    async getList2() {
      // this.topList = []
      // this.newsList = []
      let res = await this.$http.post(this.$api.listArticle, {
        current: 1,
        size: 1,
        typeId: `1,${Number(this.current2) + 2}`,
        status: "1",
        top: "1",
      });
      this.topList = res.data.data.records;
      // localStorage.removeItem('newsListdd')
      // localStorage.setItem("newsListdd", JSON.stringify(this.topList));
    },
    async getSwiperList() {
      let res = await this.$http.post(this.$api.listSlideshow, {
        location: "1",
        status: "1",
      });
      this.swiperList = res.data.data || [
        {
          imgUrl: require("@/assets/sdImages/home/banner.png"),
          description:
            "智能客服满足客户对内队外全渠道智能服务场景，赋能企业降本增效，搭建智能客服体系",
        },
      ];
      this.$nextTick(() => {
        this.initSwiper();
      });
    },
    toPagenews(row) {
      sessionStorage.setItem("conid", row.id);
      this.$router.push({
        name: "teamdetal",
        params: {
          detail: row,
        },
      });
    },
    tosomePage(row) {
      this.$router.push({
        path: row.button1Link,
      });
      // this.$router.push({
      //   name: row.button1Link,
      //   params: {
      //     detail: row.button1Link ? row.button1Link.split('?')[1] : '',
      //   },
      // });
    },
    tosomePage2(row) {
      this.$router.push({
        path: row.button2Link,
      });
      // this.$router.push({
      //   name: row.button2Link,
      //   params: {
      //     detail: row.button2Link ? row.button2Link.split('?')[1] : '',
      //   },
      // });
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    toNews(item) {
      // sessionStorage.setItem("conid", 19);
      // this.toPage("/teamdetal");
      window.open(item.link, "_blank");
    },
    toOpen(e) {
      window.open(e);
    },
    toPage(e) {
      this.$router.replace({ path: e });
    },
    //海量客户案例
    async getCaseNav() {
      this.caseNav = [
        { id: 1, name: "政务及公共服务" },
        { id: 2, name: "智慧农业" },
        { id: 3, name: "文化传媒" },
        { id: 4, name: "新零售" },
        { id: 5, name: "工业与能源" },
        { id: 6, name: "汽车出行" },
      ];
      this.$nextTick(() => {
        this.initCase();
      });
      // this.getCase();
      return;

      try {
        let {
          data: {
            code,
            data: { list },
          },
        } = await this.$http.get(this.$api.projectCate);
        // console.log(code, list);
        // this.caseNav = list;
        this.caseNav = [
          { id: 1, name: "政务及公共服务" },
          { id: 2, name: "智慧农业" },
          { id: 3, name: "文化传媒" },
          { id: 4, name: "新零售" },
          { id: 5, name: "工业与能源" },
          { id: 6, name: "汽车出行" },
        ];
        this.$nextTick(() => {
          this.initCase();
        });
        this.getCase();
      } catch (error) {}
    },
    async getCase() {
      try {
        let {
          data: {
            code,
            data: {
              list: { data },
            },
          },
        } = await this.$http.get(this.$api.casusList, {
          is_hot: this.current ? 2 : 1,
          project_cate_id: this.current
            ? this.caseNav[this.current - 1].id
            : "",
          page: 1,
          page_size: 3,
        });
        // console.log(data);
        this.caseList = data;
      } catch (error) {
        console.log(error);
      }
    },
    //新闻
    async getNews() {
      try {
        let {
          data: {
            code,
            data: {
              list: { new_list: data },
            },
          },
        } = await this.$http.get(this.$api.newsCenter);
        this.newsList = data;
      } catch (error) {}
    },
    async getarticleList() {
      try {
        let {
          data: {
            code,
            data: {
              list: { data },
            },
          },
        } = await this.$http.get(this.$api.articleList, {
          type: 2,
        });
        // console.log(data);
        this.fqaList = data;
      } catch (error) {}
    },
    initSwiper() {
      // eslint-disable-next-line
      let vueComponent = this; //获取vue组件实例
      //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
      new Swiper("#banner", {
        loop: true, // 循环模式选项
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
        //分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //分页器按钮可点击
        },
        // autoplay: false,
        //导航器
        autoplay: {
          //自动播放,不同版本配置方式不同
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      });
    },
    initImgSwiper() {
      new Swiper("#imgSwiper", {
        loop: true, // 开启循环模式
        slidesPerView: 8, // 根据卡片的宽度自动调整每屏显示的卡片数量
        //导航器
        autoplay: {
          //自动播放,不同版本配置方式不同
          delay: 0,
          disableOnInteraction: false,
        },
        freeMode: true,
        speed: 3000,
      });
    },
    initImgSwiper2() {
      new Swiper("#imgSwiper2", {
        loop: true, // 开启循环模式
        slidesPerView: 8, // 根据卡片的宽度自动调整每屏显示的卡片数量
        // autoplay:false,
        //导航器
        autoplay: {
          //自动播放,不同版本配置方式不同
          delay: 0,
          disableOnInteraction: false,
          reverseDirection: true, // 反向播放
        },
        freeMode: true,
        speed: 2000,
      });
    },
    initImgSwiper3() {
      new Swiper("#imgSwiper3", {
        loop: true, // 开启循环模式
        slidesPerView: 8, // 根据卡片的宽度自动调整每屏显示的卡片数量
        //导航器
        autoplay: {
          //自动播放,不同版本配置方式不同
          delay: 0,
          disableOnInteraction: false,
        },
        freeMode: true,
        speed: 3000,
      });
    },
    initCase() {
      //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
      new Swiper("#case", {
        loop: false,
        breakpoints: {
          320: {
            //当屏幕宽度大于等于320
            slidesPerView: 2,
          },
          750: {
            //当屏幕宽度大于等于768
            slidesPerView: 8,
          },
        },
      });
    },
  },
  filters: {
    formatDay(e) {
      if (e) {
        return e.substr(8, 2);
      }
    },
    formatYear(e) {
      if (e) {
        return e.substr(0, 7);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  background-color: #ffffff;

  .content {
    .box {
      width: 1200px;
      margin: 0 auto;
      padding: 65px 0;

      .ld-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        height: 556px;

        .context {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          background: #f6f8fa;
          border-radius: 10px 10px 10px 10px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          width: 587px;
          height: 248px;
          padding-left: 81px;
          box-sizing: border-box;
          transition: 0.35s;

          .left-img {
            width: 46px;
            height: 46px;
            margin-right: 42px;

            img {
              width: 46px;
              height: 46px;
            }
          }

          .right-text {
            .cont {
              font-family: PingFang SC, PingFang SC;
              font-weight: 600;
              font-size: 20px;
              color: #000000;
              line-height: 44px;
              font-style: normal;
              text-transform: none;
            }
          }
        }

        .context:hover {
          transform: scale(1.02);
        }
      }

      .ul2 {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 410px;

          .item {
            width: 472px;
            height: 191px;
            background: #ffffff;
            box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.08);
            border-radius: 8px 8px 8px 8px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;
            cursor: pointer;
            padding-left: 27px;
            padding-top: 22px;
            padding-right: 15px;
            box-sizing: border-box;

            .title {
              font-family: PingFang SC, PingFang SC;
              font-weight: 600;
              font-size: 20px;
              color: #000000;
              text-align: left;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
            }

            .text {
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 16px;
              color: #666666;
              line-height: 32px;
              text-align: left;
              font-style: normal;
              text-transform: none;
            }
          }

          .act {
            border-left: 6px solid #1a95fa;

            .title {
              color: #188ff0;
            }
          }
        }

        .right {
          width: 688px;
          height: 498px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .ul3 {
        display: flex;
        justify-content: space-between;
        margin-top: 120px;

        .left {
          width: 688px;
          height: 584px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .item {
            width: 472px;
            background: #ffffff;
            box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.08);
            border-radius: 8px 8px 8px 8px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;
            cursor: pointer;
            padding-left: 27px;
            padding-top: 22px;
            padding-right: 15px;
            padding-bottom: 18px;
            box-sizing: border-box;

            .title {
              font-family: PingFang SC, PingFang SC;
              font-weight: 600;
              font-size: 20px;
              color: #000000;
              text-align: left;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
            }

            .text {
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 16px;
              color: #666666;
              line-height: 32px;
              text-align: left;
              font-style: normal;
              text-transform: none;
            }
          }

          .act {
            border-left: 6px solid #1a95fa;

            .title {
              color: #188ff0;
            }
          }
        }
      }

      .lint {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 496px;

        .card {
          width: 278px;
          height: 214px;
          background: #ffffff;
          padding: 35px 25px 25px 25px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .ibg {
            width: 45px;
            height: 45px;
          }

          .header {
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 20px;
            color: #000000;
            line-height: 44px;
            text-align: center;
            font-style: normal;
            text-transform: none;
            margin-top: 10px;
          }

          .cbox {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            line-height: 28px;
            text-align: center;
            font-style: normal;
            text-transform: none;
            margin-top: 10px;
          }
        }

        .card:hover {
          transform: scale(1.02);
          // box-shadow: 0px 0px 0.28rem 0px rgb(0 0 0 / 19%);
        }

        .flex {
          flex: 1;
          margin: 0 10px;
        }
      }

      .lint2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .card {
          width: 276px;
          height: 473px;
          background: #ffffff;
          box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.08);
          border-radius: 14px 14px 14px 14px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          padding: 35px 25px 25px 25px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .ibg {
            width: 224px;
            height: 263px;
          }

          .header {
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 20px;
            color: #000000;
            line-height: 44px;
            text-align: center;
            font-style: normal;
            text-transform: none;
            margin-top: 10px;
          }

          .cbox {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            line-height: 28px;
            text-align: center;
            font-style: normal;
            text-transform: none;
            margin-top: 10px;
          }
        }

        .card:hover {
          transform: scale(1.02);
          // box-shadow: 0px 0px 0.28rem 0px rgb(0 0 0 / 19%);
        }

        .flex {
          flex: 1;
          margin: 0 10px;
        }
      }

      .more-type-content {
        // display: flex;
        // flex-wrap: wrap;
        margin-top: 30px;

        li {
          // width: calc(25% - 8px);
          width: 188px;
          height: 367px;
          border-radius: 10px;
          margin-right: 15px;
          padding: 26px 25px;
          box-sizing: border-box;
          transition: 0.8s;

          .cont {
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 24px;
            color: #ffffff;
            line-height: 44px;
            text-align: center;
            font-style: normal;
            text-transform: none;
          }

          &:nth-child(1) {
            //
            background: linear-gradient(
                180deg,
                #3a89fe 0%,
                rgba(255, 255, 255, 0) 100%
              ),
              url(~@/assets/sdImages/case1/Maskgroup.png) center/cover no-repeat;
            margin-right: 15px;
          }

          &:nth-child(1):hover {
            width: 384px;

            .cont {
              opacity: 0;
            }
          }

          &:nth-child(2) {
            // width: 140px;
            // margin-right: 0;
            background: linear-gradient(
                180deg,
                #05ace0 0%,
                rgba(255, 255, 255, 0) 100%
              ),
              url(~@/assets/sdImages/case1/Maskgroup1.png) center/cover
                no-repeat;
          }

          &:nth-child(2):hover {
            width: 384px;

            .cont {
              opacity: 0;
            }
          }

          &:nth-child(3) {
            background: linear-gradient(
                180deg,
                #008fc4 0%,
                rgba(255, 255, 255, 0) 100%
              ),
              url(~@/assets/sdImages/case1/Maskgroup2.png) center/cover
                no-repeat;
          }

          &:nth-child(3):hover {
            width: 384px;

            .cont {
              opacity: 0;
            }
          }

          &:nth-child(4) {
            background: linear-gradient(
                180deg,
                #2571e5 0%,
                rgba(255, 255, 255, 0) 100%
              ),
              url(~@/assets/sdImages/case1/Maskgroup3.png) center/cover
                no-repeat;
          }

          &:nth-child(4):hover {
            width: 384px;

            .cont {
              opacity: 0;
            }
          }

          &:nth-child(5) {
            background: linear-gradient(
                180deg,
                #08b9cb 0%,
                rgba(255, 255, 255, 0) 100%
              ),
              url(~@/assets/sdImages/case1/Maskgroup4.png) center/cover
                no-repeat;
          }

          &:nth-child(5):hover {
            width: 384px;

            .cont {
              opacity: 0;
            }
          }
        }

        .active {
          // width: calc(100% - 152px);
          width: 384px;
          // max-width: 440px;

          .cont {
            opacity: 0;
          }
        }
      }

      .btop {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0px auto 60px;

        .title {
          // font-size: 32px;
          // font-weight: 500;
          // color: #1D2129;
          // line-height: 45px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 32px;
          color: #1e1e1e;
          line-height: 48px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          // margin-bottom: 13px;
        }

        .tips {
          // font-size: 16px;
          // font-weight: 400;
          // color: #646566;
          // line-height: 22px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #8d8f94;
          line-height: 30px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }

      .tscont {
        height: 536px;
        flex-direction: column;
      }

      .cont {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        // margin: 31px 0;
        .tsCard {
          background: url(~@/assets/sdImages/home/Rectangle10113.png) no-repeat;
          background-size: cover;
          background-position: center center;
          z-index: 1;
        }

        .tsCard2 {
          background: url(~@/assets/sdImages/home/Rectangle10068.png) no-repeat;
          background-size: cover;
          background-position: center center;
          z-index: 1;
        }

        .tsCard3 {
          background: url(~@/assets/sdImages/home/Rectangle10069.png) no-repeat;
          background-size: cover;
          background-position: center center;
          z-index: 1;
        }

        .tsCard4 {
          background: url(~@/assets/sdImages/home/Rectangle10235.png) no-repeat;
          background-size: cover;
          background-position: center center;
          z-index: 1;
        }

        .tsCard5 {
          background: url(~@/assets/sdImages/home/Rectangle10113.png) no-repeat;
          background-size: cover;
          background-position: center center;
          z-index: 1;
        }

        .tsCard6 {
          background: url(~@/assets/sdImages/home/Rectangle10094.png) no-repeat;
          background-size: cover;
          background-position: center center;
          z-index: 1;
        }

        .tsCard7 {
          background: url(~@/assets/sdImages/home/Rectangle10113.png) no-repeat;
          background-size: cover;
          background-position: center center;
          z-index: 1;
        }

        .tsCard8 {
          background: url(~@/assets/sdImages/home/Rectangle10095.png) no-repeat;
          background-size: cover;
          background-position: center center;
          z-index: 1;
        }

        .card {
          width: 290px;
          height: 250px;
          // background: linear-gradient(180deg, #F3F7FD 0%, #FFFFFF 100%);
          // box-shadow: 0px 0px 20px 0px rgba(16, 38, 80, 0.1);
          border-radius: 2px;
          // border: 1px solid #EBEDF0;
          // padding: 25px;
          // box-sizing: border-box;
          position: relative;
          // margin-bottom: 16px;
          // transform: translateY(-16px);
          overflow: hidden;

          img {
            width: 100%;
          }

          .titleAndBtn {
            width: 100%;
            text-align: center;
            margin-top: 179px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 22px;
            color: #ffffff;
            line-height: 30px;
            font-style: normal;
            text-transform: none;

            img {
              width: 16px;
              height: 16px;
            }
          }

          .header {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #ebedf0;
            padding: 0px 0 13px;
            margin-bottom: 13px;

            .ico {
              width: 36px;
              height: 36px;
            }

            .title {
              font-size: 18px;
              font-weight: 500;
              color: #1d2129;
              line-height: 25px;
              margin-left: 10px;
            }
          }

          .cbox {
            // font-size: 14px;
            // font-weight: 400;
            // color: #646566;
            // line-height: 20px;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // height: 114px;

            // .btn {
            // 	width: 100px;
            // 	height: 38px;
            // 	background: #FFFFFF;
            // 	box-shadow: 0px 5px 20px 0px rgba(12, 65, 189, 0.1);
            // 	border-radius: 2px;
            // 	border: 1px solid #2B6BFF;
            // 	font-size: 14px;
            // 	font-family: PingFangSC-Medium, PingFang SC;
            // 	font-weight: 500;
            // 	color: #2B6BFF;
            // 	line-height: 20px;
            // 	text-shadow: 0px 5px 20px rgba(12, 65, 189, 0.1);
            // 	display: flex;
            // 	align-items: center;
            // 	justify-content: center;
            // 	display: none;
            // 	cursor: pointer;
            // }
            width: 100%;
            height: 286px;
            background: linear-gradient(
              180deg,
              rgba(58, 137, 254, 0.8) 0%,
              #0768fe 100%
            );
            border-radius: 6px 6px 6px 6px;
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;

            .cbox-content {
              width: 80%;
              margin: 36px auto 36px auto;
              opacity: 1;

              .btns {
                display: flex;
                justify-content: center;
                transition: all 0.3s ease-in-out;

                .free-btn {
                  width: 90px;
                  background: #ffffff;
                  border-radius: 4px 4px 4px 4px;
                  border: 1px solid #3a89fe;
                  font-family: Roboto, Roboto;
                  font-weight: 400;
                  font-size: 14px;
                  color: #1973fc;
                  text-align: center;
                  font-style: normal;
                  text-transform: none;
                  box-sizing: border-box;
                  margin-right: 10px;
                  height: 34px;
                  line-height: 34px;
                  cursor: pointer;
                }

                .detail-btn {
                  width: 90px;
                  border-radius: 4px 4px 4px 4px;
                  border: 1px solid #ffffff;
                  font-family: Roboto, Roboto;
                  font-weight: 400;
                  font-size: 14px;
                  color: #ffffff;
                  text-align: center;
                  font-style: normal;
                  text-transform: none;
                  box-sizing: border-box;
                  height: 34px;
                  line-height: 34px;
                  cursor: pointer;
                }
              }

              img {
                width: 16px;
                height: 16px;
                position: absolute;
                bottom: 29px;
              }

              .p1 {
                font-family: PingFang SC, PingFang SC;
                font-weight: 600;
                font-size: 22px;
                color: #ffffff;
                line-height: 30px;
                font-style: normal;
                text-transform: none;
                margin-bottom: 19px;
              }

              .p2 {
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #ffffff;
                line-height: 26px;
                font-style: normal;
                text-transform: none;
                text-align: left;
                height: 130px;
              }
            }
          }

          // .cbox:hover {
          // 	span {
          // 		display: none;
          // 	}

          // 	.btn {
          // 		display: flex;
          // 		transition: all 0.3s;
          // 	}
          // }
        }

        .upCard {
          transform: translateY(12px);
        }

        .downCard {
          transform: translateY(-8px);
        }

        .upCard:hover {
          height: 266px;
          transform: translateY(-4px);
          transition: all 0.5s ease-in-out;
        }

        .downCard:hover {
          height: 266px;
          transform: translateY(8px);
          transition: all 0.5s ease-in-out;
        }

        .upCard:hover .cbox {
          display: block;
          animation: slideUp 0.5s forwards;
        }

        .downCard:hover .cbox {
          display: block;
          animation: downslideUp 0.5s forwards;
        }

        @keyframes slideUp {
          0% {
            height: 0;
            top: 250px;
          }

          100% {
            height: 300px;
            top: -17px;
          }
        }

        @keyframes downslideUp {
          0% {
            height: 0;
            bottom: 250px;
          }

          100% {
            height: 286px;
            bottom: -30px;
          }
        }

        .card:hover .titleAndBtn {
          display: none;
        }

        .card::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url(~@/assets/sdImages/home/Rectangle10236.png) no-repeat;
          background-size: cover;
          background-position: center center;
          z-index: -1;
        }

        .card2 {
          width: 373px;
          height: 113px;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 6px;
          border: 2px solid #ffffff;
          backdrop-filter: blur(4px);
          padding: 20px 30px;
          box-sizing: border-box;
          margin-bottom: 32px;

          .header {
            display: flex;
            align-items: center;
            margin-bottom: 12px;

            .ico {
              width: 26px;
              height: 26px;
            }

            .title {
              font-size: 18px;
              font-weight: 500;
              color: #1d2129;
              line-height: 25px;
              margin-left: 6px;
            }
          }

          .cbox {
            font-size: 14px;
            font-weight: 400;
            color: #646566;
            line-height: 20px;
          }
        }

        .sollcor {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;

          // overflow-x: auto;
          .left,
          .right {
            width: 28px;
            height: 28px;
            border-radius: 100%;
            position: absolute;
            z-index: 99;

            img {
              width: 100%;
              height: 100%;
              border-radius: 100%;
              cursor: pointer;
            }
          }

          .left {
            left: -45px;
          }

          .right {
            right: -45px;
          }
        }

        .slide {
          width: 100%;
          overflow: auto;
          display: flex;
          align-items: center;
          min-width: 1200px;
        }

        .card3 {
          width: 384px;
          min-width: 384px;
          height: 166px;
          background: #ffffff;
          box-shadow: 0px 0px 20px 0px rgba(16, 38, 80, 0.1);
          border-radius: 2px;
          padding: 32px;
          box-sizing: border-box;

          .cbox {
            display: flex;
            align-items: flex-start;

            .cico {
              width: 32px;
              height: 32px;
              background: #ffffff;
              border-radius: 2px;

              // border: 1px solid #EBEDF0;
              img {
                width: 100%;
                height: 100%;
              }
            }

            .txt {
              flex: 1;
              font-size: 14px;
              font-weight: 400;
              color: #646566;
              line-height: 20px;
              margin-left: 16px;
              min-height: 80px;
              margin-top: 2px;
            }
          }

          .cbotttom {
            width: 100%;
            margin-top: 4px;
            text-align: right;
          }
        }

        .m24 {
          margin: 0 24px;
        }

        .abg-radio {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          .abg-nav {
            border-radius: 42px 42px 42px 42px;
            border: 1px solid #c1c5ca;
            padding: 12px 40px 12px 40px;
            margin-right: 24px;
            // font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 18px;
            color: #1e1e1e;
            font-style: normal;
            text-transform: none;
            cursor: pointer;
          }

          .active {
            background: #ffffff;
            border: 1px solid #5398fe;
          }
        }

        .abg-content {
          display: flex;
          margin-top: 60px;

          .abg-left {
            margin-right: 24px;
            // background: #ffffff;
            // height: 500px;
            border-radius: 12px 12px 0px 0px;

            .img {
              width: 543px;
              height: 322px;
              border-radius: 12px 12px 0px 0px;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
                // transition: transform 0.3s ease;
                transition: 0.35s;
              }
            }

            .abg-left-content {
              cursor: pointer;
              background: #ffffff;
              border-radius: 0px 0px 12px 12px;
              padding: 18px 25px 18px 25px;
              height: 135px;

              .title {
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 20px;
                color: #000000;
                text-align: left;
                font-style: normal;
                text-transform: none;
                margin-bottom: 13px;
              }

              .content {
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #8d8f94;
                text-align: left;
                font-style: normal;
                text-transform: none;
              }

              .time {
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #8d8f94;
                text-align: left;
                font-style: normal;
                text-transform: none;
                margin-bottom: 20px;
              }
            }
          }

          .abg-left img:hover {
            transform: scale(1.1);
          }

          .abg-right {
            .abg-right-content {
              cursor: pointer;
              width: 650px;
              height: 153px;
              padding: 20px;
              background: #ffffff;
              border-radius: 12px 12px 12px 12px;
              box-sizing: border-box;
              margin-bottom: 16px;

              .title {
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 20px;
                color: #000000;
                text-align: left;
                font-style: normal;
                text-transform: none;
                margin-bottom: 10px;
              }

              .content {
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #8d8f94;
                text-align: left;
                font-style: normal;
                text-transform: none;
                margin-bottom: 25px;
              }

              .time-more {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .time {
                  font-family: PingFang SC, PingFang SC;
                  font-weight: 400;
                  font-size: 14px;
                  color: #8d8f94;
                  text-align: left;
                  font-style: normal;
                  text-transform: none;
                }

                .more {
                  font-family: PingFang SC, PingFang SC;
                  font-weight: 400;
                  font-size: 14px;
                  color: #8d8f94;
                  text-align: left;
                  font-style: normal;
                  text-transform: none;
                  display: flex;
                  align-items: center;

                  .group14 {
                    width: 20px;
                    height: 20px;
                    margin-left: 5px;
                  }

                  .group13 {
                    width: 20px;
                    height: 20px;
                    margin-left: 5px;
                    display: none;
                  }
                }
              }
            }
          }

          .abg-right-content:hover {
            border-bottom: 2px solid #3a89fe;

            .title {
              color: #3a89fe;
            }

            .time-more {
              .more {
                color: #3a89fe;

                .group14 {
                  display: none;
                }

                .group13 {
                  display: block;
                }
              }
            }
          }

          .abg-left-content:hover {
            border-bottom: 2px solid #3a89fe;

            .title {
              color: #3a89fe;
            }
          }
        }
      }
    }
  }


  .hbg {
    background: #f1f4fa;
  }

  .abg {
    // background: url(~@/assets/ximg/bj-wsmxzwm@2x.png) no-repeat;
    // background-size: cover;
    // background-position: center center;
    background: #ffffff;
  }

  .hzbg {
    // background: url(~@/assets/ximg/background-parter@2x.png) no-repeat;
    // background-size: cover;
    // background-position: center center;
    background: #ffffff;
  }
}

.ish5 {
  .content {
    .box {
      width: auto;
      padding: 32px;

      .btop {
        .title {
          font-size: 36px;
        }

        .tips {
          font-size: 20px;
          line-height: 38px;
        }
      }

      .cont {
        .card {
          width: calc(50% - 16px);
          height: 381px;

          .header {
            .title {
              font-size: 26px;
              white-space: nowrap;
            }
          }

          .cbox {
            height: 381px;
            font-size: 22px;
            line-height: 32px;

            .btn {
              padding: 12px;
            }
          }
        }

        .card2 {
          width: calc(50% - 16px);
          height: 228px;

          .header {
            .title {
              font-size: 26px;
            }
          }

          .cbox {
            font-size: 22px;
            line-height: 38px;
          }
        }

        .sollcor {
          // overflow: auto;
          height: 100%;
          flex-direction: column;

          .left,
          .right {
            display: none;
          }

          .slide {
            flex-direction: column;
            min-width: auto;
          }

          .card3 {
            width: 680px;
            height: auto;
            margin: 12px;

            .cbox {
              .txt {
                font-size: 26px;
                line-height: 32px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 0px) {
  .banner {
    max-width: 100%;
    min-width: 1200px;
    width: 100%;
    // margin-bottom: 19px;

    .banimg {
      max-width: 100%;
      min-width: 1200px;
      width: 100%;
      height: 550px;
      position: relative;
      text-align: left;
      background: url(~@/assets/sdImages/case2/guanfu.png) no-repeat;
      background-size: cover;
      background-position-x: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        width: 1200px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 44px;
        // color: #262626;
        color: #ffffff;
        font-style: normal;
        text-transform: none;
      }

      .tip {
        width: 1200px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        // color: #262626;
        font-style: normal;
        text-transform: none;
        margin-top: 13px;
      }

      .btn-box {
        width: 1200px;
        margin-top: 32px;
        display: flex;

        .btn {
          width: 160px;
          height: 49px;
          background: #3a89fe;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #3a89fe;
          cursor: pointer;
          font-family: Roboto, Roboto;
          font-weight: 400;
          font-size: 18px;
          color: #ffffff;
          line-height: 49px;
          text-align: center;
          font-style: normal;
          text-transform: none;
          box-sizing: border-box;
          margin-right: 16px;
        }

        .btn2 {
          width: 160px;
          height: 49px;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #3a89fe;
          cursor: pointer;
          font-family: Roboto, Roboto;
          font-weight: 400;
          font-size: 18px;
          color: #3a89fe;
          line-height: 49px;
          text-align: center;
          font-style: normal;
          text-transform: none;
          box-sizing: border-box;
        }

        .btn:hover {
          opacity: 0.9;
        }
      }
    }
  }

  .product-nav {
    position: relative;
    z-index: 2;

    // margin-top: -30px;
    .Gwidth {
      margin: 20px auto;
    }

    li {
      box-shadow: 0px 2px 10px 0px rgba(29, 33, 41, 0.1);
      padding: 24px 50px 24px 90px;
      margin-bottom: 10px;
      position: relative;

      a {
        font-size: 28px;
        font-weight: 400;
        color: #969799;

        h4 {
          font-size: 32px;
          font-weight: 500;
          color: #1d2129;
          line-height: 25px;
          text-shadow: 0px 2px 10px rgba(29, 33, 41, 0.1);
          // background: url(~@/assets/ico/icon-jiantou.png) right center no-repeat;
          background-size: 12px 12px;
          margin-bottom: 10px;
        }

        .more {
          position: absolute;
          opacity: 0;
          left: 0;
          bottom: 0;
          transition: 0.35s;
        }
      }

      &:nth-child(1) {
        // background: #ffffff url(~@/assets/ico/icon-nft.png) 50px 17px no-repeat;
        background-size: 38px 38px;
      }

      &:nth-child(2) {
        // background: #ffffff url(~@/assets/ico/icon-yuanyuzhou.png) 50px 17px no-repeat;
        background-size: 38px 38px;
      }

      &:nth-child(3) {
        // background: #ffffff url(~@/assets/ico/icon-kaifa.png) 50px 17px no-repeat;
        background-size: 38px 38px;
      }
    }
  }

  .Gtitle {
    font-size: 38px;
    font-weight: 500;
    color: #1d2129;
    text-align: center;
    margin: 60px 0;
  }

  .case {
    // padding: 20px 0;
    // background-color: #f5f6fa;
    height: 821px;
    background: url(~@/assets/sdImages/case2/Group12486dd.png) no-repeat;

    &-tab {
      display: flex;
      justify-content: center;

      // border-bottom: 1px solid #dcdee0;
      :deep(.swiper-slide) {
        margin-right: 14px;
      }

      .case-nav {
        text-align: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #4f5861;
        font-style: normal;
        text-transform: none;
        background: #f2f7ff;
        border-radius: 40px 40px 40px 40px;
        padding: 14px 0;
        cursor: pointer;

        &.active {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #1e1e1e;
          font-style: normal;
          text-transform: none;
          background: #ffffff;
          border-radius: 100px 100px 100px 100px;
          border: 2px solid #3181f6;
        }
      }
    }

    &-content {
      &-text {
        margin-top: 95px;
        margin-right: 69px;

        .p1 {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 32px;
          color: #15191d;
          line-height: 44px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 18px;
        }

        .p1:hover {
          color: #3a89fe;
        }

        .p2 {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 18px;
          color: #4f5861;
          line-height: 28px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 28px;
        }

        .p3 {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #3181f6;
          line-height: 26px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 52px;
          cursor: pointer;

          img {
            width: 21px;
            height: 18px;
            margin-left: 6px;
          }
        }

        .p3:hover img {
          animation: slideInLeft 1s forwards;
        }

        @keyframes slideInLeft {
          from {
            transform: translate3d(-100%, 0, 0);
            visibility: visible;
          }

          to {
            transform: translate3d(0, 0, 0);
          }
        }

        .attr {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          width: 65%;

          .attr-info {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            margin-bottom: 25px;
            width: 50%;

            img {
              width: 23px;
              height: 23px;
              margin-right: 10px;
            }

            .title {
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 16px;
              color: #1e1e1e;
              text-align: left;
              font-style: normal;
              text-transform: none;
            }
          }
        }
      }

      &-img {
        margin-top: 74px;
        width: 646px;
        height: 382px;
        flex-shrink: 0;
        overflow: hidden;
        border-radius: 22px;

        img {
          width: 100%;
          height: 100%;
          // transition: transform 0.3s ease;
          transition: 0.35s;
          object-fit: cover;
        }
      }

      &-img img:hover {
        transform: scale(1.1);
      }

      &-item {
        width: 100%;
        background: #ffffff;
        border-radius: 2px;
        margin-top: 20px;
        transition: 0.35s;

        &-img {
          font-size: 0;
          overflow: hidden;

          img {
            width: 100%;
            transition: 0.35s;
            object-fit: cover;
          }
        }

        &-cont {
          padding: 30px 30px 40px 30px;
          box-sizing: border-box;

          &-title {
            font-size: 28px;
            font-weight: 500;
            color: #1d2129;
          }

          &-des {
            font-size: 28px;
            line-height: 36px;
            font-weight: 400;
            color: #646566;
            margin-top: 8px;
          }

          &-tags {
            margin-top: 24px;

            span {
              padding: 4px 14px;
              display: inline-block;
              font-size: 26px;
              font-weight: 400;
              color: #2b6bff;
              // line-height: 20px;
              background: #f2f3f5;
              border-radius: 2px;
              margin: 0 8px 8px 0;
            }
          }
        }
      }
    }

    &-more {
      width: 180px;
      height: 40px;
      border-radius: 2px;
      border: 1px solid #2b6bff;
      text-align: center;
      margin: 50px auto 0 auto;

      a {
        font-size: 28px;
        font-weight: 400;
        color: #2b6bff;
        line-height: 40px;
      }
    }
  }

  .opts {
    display: flex;
    // margin-top: 75px;
    .opts-child {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 172px;
      height: 156px;
      background: #ffffff;
      border-left: 1px solid #e6e6e6;
      border-right: 1px solid #e6e6e6;
      border-top: 1px solid #e6e6e6;

      .img {
        width: 45px;
        height: 45px;

        img {
          width: 45px;
          height: 45px;
        }
      }

      .title {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 20px;
        color: #000000;
        font-style: normal;
        text-transform: none;
        margin-top: 15px;
      }
    }

    .active {
      background: #5096fe;

      .title {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 20px;
        color: #ffffff;
        font-style: normal;
        text-transform: none;
      }
    }
  }

  .opts-text {
    width: 1200px;
    margin: 0 auto;
    background: #5096fe;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    padding: 20px 30px;
    box-sizing: border-box;

    .left-flag {
      display: flex;
      justify-content: start;

      img {
        width: 30px;
        height: 26px;
      }
    }

    .center-content {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 20px;
      color: #ffffff;
      line-height: 28px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-left: 50px;
    }

    .right-flag {
      display: flex;
      justify-content: end;

      img {
        width: 30px;
        height: 26px;
      }
    }
  }

  .home-more {
    padding: 20px 0;

    &-content {
      display: flex;
      flex-wrap: wrap;

      &-item {
        transition: 0.35s;
        width: calc(50% - 14px);
        margin-right: 24px;
        background: #ffffff;
        box-shadow: 0px 0px 85px 0px rgba(29, 33, 41, 0.05);
        border-radius: 2px;
        border: 1px solid #ebedf0;
        padding: 20px;
        box-sizing: border-box;
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:nth-child(2n) {
          margin-right: 0;
        }

        &:last-child {
          align-items: center;

          .home-more-content-item-ico {
            img {
              width: 32px;
            }
          }

          h5 {
            font-weight: 400;
            color: #2b6bff;
          }
        }

        &-ico {
          img {
            width: 55px;
          }
        }

        h5 {
          font-size: 28px;
          font-weight: 600;
          color: #1d2129;
          line-height: 28px;
        }

        &-des {
          font-size: 28px;
          font-weight: 400;
          color: #646566;
          line-height: 34px;
          margin-top: 8px;
        }
      }
    }
  }

  .about-us {
    // background: url(~@/assets/images/bj-wsmxzwm.png) center/cover no-repeat;
    padding: 20px 0;

    &-header {
      &-title {
        font-size: 38px;
        font-weight: 500;
        color: #1d2129;
      }

      &-des {
        font-size: 24px;
        font-weight: 400;
        color: #323233;
        margin-top: 5px;
      }
    }

    &-content {
      display: flex;
      flex-wrap: wrap;

      &-item {
        width: 33.33%;
        text-align: center;

        &-ico {
          font-size: 0;

          img {
            width: 100px;
          }
        }

        &-title {
          span {
            font-size: 28px;
            font-weight: 600;
            color: #1d2129;

            &:nth-child(1) {
              font-size: 30px;
            }
          }
        }
      }
    }
  }

  .swiper-container1 > .swiper-wrapper {
    -webkit-transition-timing-function: linear;
    /*之前是ease-out*/
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
  }

  .swiper-container1 {
    overflow: visible;
  }

  .relative-filter {
    position: relative;

    .left-zg {
      position: absolute;
      top: 0;
      left: 0;
      width: 240px;
      height: 100%;
      background: linear-gradient(270deg, #fff 29.49%, hsla(0, 0%, 100%, 0));
      transform: matrix(-1, 0, 0, 1, 0, 0);
      z-index: 99;
    }

    .right-zg {
      position: absolute;
      top: 0;
      right: 0;
      width: 240px;
      height: 100%;
      background: linear-gradient(270deg, #fff 29.49%, hsla(0, 0%, 100%, 0));
      z-index: 99;
    }
  }

  .partner {
    padding: 10px 0;
    background: #fff;

    .imgBox {
      // width: 50%;
      width: 180px;
      height: 82px;
      background: #ffffff;
      box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.06);
      border-radius: 10px 10px 10px 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      transform: skewX(-16deg);
      font-size: 0;
      text-align: center;
      position: relative;
      z-index: 1;
      // padding: 10px 0;
      margin: 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      // &::after {
      // 	content: "";
      // 	border: 1px solid #ebedf0;
      // 	width: 200%;
      // 	height: 200%;
      // 	transform-origin: 0 0;
      // 	transform: scale(0.5);
      // 	position: absolute;
      // 	left: 0;
      // 	top: 0;
      // 	transition: 0.35s;
      // }

      // transition: 0.35s;
    }

    img {
      max-width: 172px;
      transform: skewX(15deg);
      width: 128px;
      height: 55px;
      object-fit: cover;
    }

    &-cont {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      background: #fff;

      // &::after {
      // 	content: "";
      // 	border: 1px solid #ebedf0;
      // 	width: 200%;
      // 	height: 200%;
      // 	transform-origin: 0 0;
      // 	transform: scale(0.5);
      // 	position: absolute;
      // 	left: 0;
      // 	top: 0;
      // }

      .imgBox {
        // width: 50%;
        width: 180px;
        height: 82px;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        transform: skewX(-16deg);
        font-size: 0;
        text-align: center;
        position: relative;
        z-index: 1;
        padding: 10px 0;
        margin: 10px;

        // &::after {
        // 	content: "";
        // 	border: 1px solid #ebedf0;
        // 	width: 200%;
        // 	height: 200%;
        // 	transform-origin: 0 0;
        // 	transform: scale(0.5);
        // 	position: absolute;
        // 	left: 0;
        // 	top: 0;
        // 	transition: 0.35s;
        // }

        // transition: 0.35s;
      }

      img {
        max-width: 172px;
        transform: skewX(15deg);
        width: 80%;
        height: 80%;
      }
    }
  }

  .news {
    background: #f5f6fa;
    padding: 20px 0;

    &-content {
      &-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 400;
        color: #333333;
        font-size: 32px;
        border-bottom: 1px solid #dfdfdf;
        margin-bottom: 20px;

        &-left,
        &-right {
          line-height: 40px;
          display: none;
          padding: 0 5px;
        }

        &-left {
          display: block;
          border-bottom: 3px solid #2b6bff;
        }
      }

      &-cont {
        .news-hot {
          font-size: 0;
          position: relative;
          margin-bottom: 10px;

          img {
            width: 100%;
          }

          &-cont {
            background: rgba(#000000, 0.88);
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            padding: 10px 20px;
            box-sizing: border-box;

            &-title {
              font-size: 28px;
              color: #ffffff;
            }

            &-des {
              font-size: 24px;
              color: #ffffff;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }

        .news-newlist {
          background: #ffffff;
          box-shadow: 0px 0px 2px 0px rgb(81 81 81 / 15%);
          box-sizing: border-box;
          padding: 16px 21px;
          margin-bottom: 10px;
          position: relative;

          &::after {
            content: "";
            width: 16px;
            height: 14px;
            // background: url(~@/assets/ico/back.png) center center no-repeat;
            background-size: 16px 14px;
            position: absolute;
            right: 21px;
            bottom: 16px;
          }

          &-top {
            display: flex;

            &-left {
              margin-right: 15px;
              text-align: center;

              &-day {
                font-size: 38px;
                font-weight: 600;
                color: #333333;
              }

              &-year {
                font-size: 28px;
                font-weight: 400;
                color: #999999;
                white-space: nowrap;
                margin-top: 14px;
                position: relative;

                &::after {
                  content: "";
                  width: 20px;
                  height: 2px;
                  background-color: #333333;
                  position: absolute;
                  left: 50%;
                  top: -10px;
                  transform: translateX(-50%);
                }
              }
            }

            &-right {
              &-title {
                font-size: 32px;
                color: #333333;
                // white-space: nowrap;
                // text-overflow: ellipsis;
                // overflow: hidden;
              }

              &-des {
                margin-top: 10px;
                font-size: 28px;
                color: #666666;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
          }

          &-tags {
            font-size: 28px;
            font-weight: 400;
            color: #999999;
            margin-top: 10px;

            span {
              margin-right: 5px;
              margin-bottom: 5px;
            }
          }
        }

        .fqa {
          background: #ffffff;
          box-shadow: 0px 0px 2px 0px rgb(81 81 81 / 15%);
          box-sizing: border-box;
          padding: 20px;
          color: #333333;

          li {
            a {
              // background: url(~@/assets/ico/jt1.png) left center no-repeat;
              background-size: 6px 11px;
              padding-left: 15px;
              display: block;
              font-size: 32px;
              font-weight: 400;
              color: #333333;
              text-align: left;
              line-height: 44px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 751px) {
  .htop {
    height: 65px;
  }

  .case {
    &-tab {
      ul {
        li {
          padding: 22px 26px;
        }
      }

      .case-nav {
        font-size: 18px;

        &.active {
          font-size: 18px;
        }
      }
    }

    &-content {
      display: flex;
      // flex-wrap: wrap;

      &-head {
        font-size: 18px;
      }

      &-item {
        width: calc(100% / 2 - 10px);
        margin-right: 20px;

        &:nth-child(2n) {
          margin-right: 0;
        }

        &-cont {
          &-title {
            font-size: 16px;
          }

          &-des {
            line-height: 22px;
            font-size: 16px;
          }

          &-tags {
            span {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }

    &-more {
      width: 144px;
      height: 40px;
      border-radius: 2px;
      border: 1px solid #2b6bff;
      text-align: center;
      margin: 50px auto 0 auto;

      a {
        font-size: 16px;
        font-weight: 400;
        color: #2b6bff;
        line-height: 40px;
      }
    }
  }

  // .partner {
  // 	&-cont {
  // 		.img {
  // 			width: calc(100% / 3);
  // 		}
  // 	}
  // }
}

@media screen and (min-width: 870px) {
  .product-nav {
    position: relative;
    z-index: 2;
    margin-top: 0;

    ul {
      box-shadow: 0px 2px 10px 0px rgba(29, 33, 41, 0.1);
      background-color: #ffffff;
      position: absolute;
      left: 50%;
      bottom: -60px;
      transform: translateX(-50%);

      li {
        flex: 1;
        padding: 24px 10px 24px 50px;
        margin-top: 0;
        margin-bottom: 0;
        box-shadow: none;
        width: 33.33%;
        position: absolute;
        box-sizing: border-box;
        bottom: 0;

        a {
          font-size: 14px;

          h4 {
            font-size: 18px;
          }

          p {
            min-height: 36px;
          }
        }

        &:nth-child(1) {
          // background: #ffffff url(~@/assets/ico/icon-nft.png) 10px 17px no-repeat;
          background-size: 38px 38px;
        }

        &:nth-child(2) {
          left: 33.33%;
          // background: #ffffff url(~@/assets/ico/icon-yuanyuzhou.png) 10px 17px no-repeat;
          background-size: 38px 38px;
        }

        &:nth-child(3) {
          left: 66.66%;
          // background: #ffffff url(~@/assets/ico/icon-kaifa.png) 10px 17px no-repeat;
          background-size: 38px 38px;
        }

        &:hover {
          a {
            color: #abc4ff;

            .more,
            h4 {
              color: #ffffff;
              background: none;
            }

            .more {
              position: relative;
              opacity: 1;
              margin-top: 27px;
            }
          }

          &:nth-child(1) {
            // background: #2b6bff url(~@/assets/ico/icon-nft2.png) 10px 17px no-repeat;
            background-size: 38px 38px;
          }

          &:nth-child(2) {
            // background: #2b6bff url(~@/assets/ico/icon-yuanyuzhou2.png) 10px 17px no-repeat;
            background-size: 38px 38px;
          }

          &:nth-child(3) {
            // background: #2b6bff url(~@/assets/ico/icon-kaifa2.png) 10px 17px no-repeat;
            background-size: 38px 38px;
          }
        }
      }
    }
  }

  .about-us {
    padding: 40px 0;

    &-header {
      &-title {
        font-size: 24px;
        font-weight: 500;
        color: #1d2129;
      }

      &-des {
        font-size: 16px;
        font-weight: 400;
        color: #323233;
        margin-top: 5px;
      }
    }

    &-content {
      margin-top: 80px;

      &-item {
        width: 20%;
      }

      &-title {
        span {
          font-size: 14px;
          font-weight: 600;
          color: #1d2129;

          &:nth-child(1) {
            font-size: 30px;
          }
        }
      }
    }
  }

  // .partner {
  // 	padding: 40px 0 0;

  // 	&-cont {
  // 		margin-top: 40px;

  // 		.img {
  // 			width: 25%;
  // 		}
  // 	}
  // }

  .news {
    padding: 40px 0;

    &-content {
      &-head {
        font-size: 18px;
      }
    }
  }
}

@media screen and (min-width: 1100px) {
  .case {
    &-content {
      &-item {
        width: calc(100% / 3 - 10px);
        margin-right: 15px;
        margin-top: 40px;

        &:hover {
          box-shadow: 0px 2px 10px 0px rgba(29, 33, 41, 0.1);

          .case-content-item-img {
            overflow: hidden;

            img {
              transform: scale(1.2);
            }
          }
        }

        &:nth-child(2n) {
          margin-right: 15px;
        }

        &:nth-child(3n) {
          margin-right: 0px;
        }

        &-cont {
          padding: 20px;
        }
      }
    }

    &-more {
      &:hover {
        background-color: #2b6bff;

        a {
          color: #ffffff;
        }
      }
    }
  }

  .home-more {
    padding: 40px 0;

    &-content {
      margin-top: 40px;

      &-item {
        width: calc(33.33% - 14px);
        margin-right: 16px;
        padding: 24px;

        &-des {
          font-size: 16px;
        }

        &:hover {
          transform: translateY(-16px);
        }

        &:nth-child(2n) {
          margin-right: 16px;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }

        h5 {
          font-size: 16px;
          font-weight: 600;
          color: #1d2129;
          line-height: 22px;
        }

        &-des {
          font-size: 14px;
          font-weight: 400;
          color: #646566;
          line-height: 24px;
          margin-top: 8px;
        }
      }
    }
  }

  // .partner {
  // 	padding: 40px 0 0;

  // 	&-cont {
  // 		.img {
  // 			&:hover {
  // 				box-shadow: 0px 1px 16px 0px rgba(33, 86, 210, 0.15);

  // 				&::after {
  // 					border: 2px solid #2b6bff;
  // 				}
  // 			}
  // 		}
  // 	}
  // }

  .news {
    padding: 40px 0;

    &-content {
      &-cont {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &-item {
          width: 389px;

          .news-hot {
            overflow: hidden;

            img {
              transition: 0.35s;
            }

            &:hover {
              img {
                transform: scale(1.2);
              }
            }

            &-cont {
              background: rgba(#000000, 0.88);
              position: absolute;
              width: 100%;
              left: 0;
              bottom: 0;
              padding: 10px 20px;
              box-sizing: border-box;

              &-title {
                font-size: 14px;
                color: #ffffff;
              }

              &-des {
                font-size: 12px;
                color: #ffffff;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }

          .news-newlist {
            transition: 0.35s;

            &:hover {
              transform: scale(0.98);

              .news-newlist-top {
                .news-newlist-top-right {
                  .news-newlist-top-right-title {
                    color: #2b6bff;
                  }
                }
              }
            }

            &-top {
              display: flex;

              &-left {
                margin-right: 15px;
                text-align: center;

                &-day {
                  font-size: 24px;
                  font-weight: 600;
                  color: #333333;
                }

                &-year {
                  font-size: 14px;
                  font-weight: 400;
                  color: #999999;
                  white-space: nowrap;
                  margin-top: 14px;
                  position: relative;

                  &::after {
                    content: "";
                    width: 20px;
                    height: 2px;
                    background-color: #333333;
                    position: absolute;
                    left: 50%;
                    top: -10px;
                    transform: translateX(-50%);
                  }
                }
              }

              &-right {
                &-title {
                  font-size: 16px;
                  color: #333333;
                  // white-space: nowrap;
                  // text-overflow: ellipsis;
                  // overflow: hidden;
                }

                &-des {
                  margin-top: 10px;
                  font-size: 14px;
                  color: #666666;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                }
              }
            }

            &-tags {
              font-size: 14px;
              font-weight: 400;
              color: #999999;
              margin-top: 10px;

              span {
                margin-right: 5px;
                margin-bottom: 5px;
              }
            }
          }

          .fqa {
            li {
              a {
                font-size: 16px;
              }
            }

            li:hover {
              a {
                color: #2b6bff;
                font-size: 16px;
              }
            }
          }

          &:nth-child(2) {
            width: calc(100% - 400px);
          }

          &:nth-child(3) {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1300px) {
  .product-nav {
    ul {
      li {
        padding: 24px 50px 24px 90px;

        a {
          font-size: 14px;
        }

        &:nth-child(1) {
          // background: #ffffff url(~@/assets/ico/icon-nft.png) 50px 17px no-repeat;
          background-size: 38px 38px;
        }

        &:nth-child(2) {
          // background: #ffffff url(~@/assets/ico/icon-yuanyuzhou.png) 50px 17px no-repeat;
          background-size: 38px 38px;
        }

        &:nth-child(3) {
          // background: #ffffff url(~@/assets/ico/icon-kaifa.png) 50px 17px no-repeat;
          background-size: 38px 38px;
        }

        &:hover {
          &:nth-child(1) {
            // background: #2b6bff url(~@/assets/ico/icon-nft2.png) 50px 17px no-repeat;
            background-size: 38px 38px;
          }

          &:nth-child(2) {
            // background: #2b6bff url(~@/assets/ico/icon-yuanyuzhou2.png) 50px 17px no-repeat;
            background-size: 38px 38px;
          }

          &:nth-child(3) {
            // background: #2b6bff url(~@/assets/ico/icon-kaifa2.png) 50px 17px no-repeat;
            background-size: 38px 38px;
          }
        }
      }
    }
  }

  .Gtitle {
    font-size: 32px;
    font-weight: 500;
    color: #1d2129;
    line-height: 45px;
    margin: 60px auto;
    margin-bottom: 63px;
  }

  .case {
    &-content {
      &-item {
        width: calc(100% / 3 - 16px);
        margin-right: 24px;
        margin-top: 60px;

        &:nth-child(2n) {
          margin-right: 24px;
        }

        &:nth-child(3n) {
          margin-right: 0px;
        }

        &-cont {
          padding: 30px 30px 40px 30px;
        }
      }
    }
  }

  .home-more {
    padding: 80px 0;

    &-content {
      margin-top: 60px;

      &-item {
        width: calc(25% - 18px);
        margin-right: 24px;

        &:nth-child(2n) {
          margin-right: 24px;
        }

        &:nth-child(3n) {
          margin-right: 24px;
        }

        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }

  .about-us {
    padding: 90px 0 100px 0;

    &-header {
      &-title {
        font-size: 32px;
        font-weight: 600;
      }

      &-des {
        margin-top: 14px;
      }
    }

    &-content {
      // margin-top: 135px;
      &-item {
        &-title {
          span {
            font-size: 16px;

            &:nth-child(1) {
              font-size: 40px;
            }
          }
        }
      }
    }
  }

  // .partner {
  // 	padding: 80px 0 0;
  // }

  .news {
    padding: 80px 0;

    &-content {
      &-head {
        &-right {
          display: block;
          width: 389px;
        }
      }

      &-cont {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &-item {
          width: 389px;

          .news-newlist {
            padding: 10px 21px;
            height: 127px;
            box-sizing: border-box;

            &-top {
              &-right {
                width: calc(100% - 60px);

                &-title {
                  font-size: 16px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }

                &-des {
                  font-size: 12px;
                  margin-top: 5px;
                }
              }
            }

            &-tags {
              margin-top: 5px;
            }
          }

          .news-hot {
            img {
              height: 264px;
            }
          }

          &:nth-child(2) {
            width: 389px;
          }

          &:nth-child(3) {
            width: 389px;

            .news-content-head {
              display: none;
            }
          }

          .fqa {
            li {
              a {
                line-height: 45px;
              }
            }
          }
        }
      }
    }
  }
}

.ish5 {
  .banner {
    padding-top: 140px;

    :deep(.swiper-pagination-bullets) {
      left: -200px;
      bottom: 50px;
    }

    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 100%;
    }
  }
}
</style>
